/**
 * Dropdown menu to link to individual and group focused projects
 */
import { useState } from "react";
import { ButtonDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import DropDownNavLink from "components/NavLink/DropdownNavLink";
import { paths } from "shared/constants";

/**
 * Dropdown menu to link to individual and group focused projects
 */
export default function ProjectDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prevState: boolean) => !prevState);
  return <ButtonDropdown isOpen={isOpen} toggle={toggle} className="nav-link" data-sentry-element="ButtonDropdown" data-sentry-component="ProjectDropdown" data-sentry-source-file="ProjectMenu.tsx">
      <DropdownToggle caret={true} tag="a" data-sentry-element="DropdownToggle" data-sentry-source-file="ProjectMenu.tsx">
        Projects
      </DropdownToggle>

      <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-source-file="ProjectMenu.tsx">
        <DropDownNavLink href={paths.missions.forIndividuals} data-sentry-element="DropDownNavLink" data-sentry-source-file="ProjectMenu.tsx">Projects for Individuals</DropDownNavLink>
        <DropDownNavLink href={paths.missions.forGroups} data-sentry-element="DropDownNavLink" data-sentry-source-file="ProjectMenu.tsx">Projects for Groups</DropDownNavLink>
        <DropDownNavLink href={paths.missions.root} data-sentry-element="DropDownNavLink" data-sentry-source-file="ProjectMenu.tsx">All Projects</DropDownNavLink>
      </DropdownMenu>
    </ButtonDropdown>;
}