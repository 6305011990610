"use client";

import { usePathname } from "next/navigation";
import React from "react";
export default function DropDownNavLink({
  href,
  children
}: {
  href: string;
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  let isActive = false;
  if (href === "/" && pathname === "/") {
    isActive = true;
  } else if (href !== "/") {
    isActive = pathname.startsWith(href);
  }
  return <a href={href} className={"dropdown-item nav-item" + (isActive ? " active" : "")} data-sentry-component="DropDownNavLink" data-sentry-source-file="DropdownNavLink.tsx">
      {children}
    </a>;
}