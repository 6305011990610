/**
 * URL paths for various pages
 */
export const paths = {
  about: "/about/",
  admin: "/admin/",

  auth: {
    login: "/users/login/",
    logout: "/users/logout/",
    register: "/users/register/",
  },

  cms: "/cms/",
  draftPage: "/cms/pages/:pageId/edit/preview/",

  contact: "/contact/",
  explore: "/explore/",
  home: "/",
  faq: "/faq/",

  // Missions were renamed to projects, but missions persists through the source code
  missions: {
    overview: "/project/:slug",
    collect: "/project/:slug/collect",
    data: "/project/:slug/data",
    datasheet: "/project/:slug/data/:datasheet",
    datasheetEdit: "/project/:slug/data/:datasheet/edit",
    datasheetEditPassphrase: "/project/:slug/data/:datasheet/edit?passphrase=",
    discussion: "/project/:slug/discussion",

    validate: "/project/:slug/validate",

    forGroups: "/project/for-groups/",
    forIndividuals: "/project/for-individuals/",

    mission_catch_all: "/project/:slug/*",
    mission: "/project/:slug",
    protocol: "/project/:slug/protocol",
    results: "/project/:slug/results",
    root: "/project/",
  },

  partners: "/partners/",
  privacyPolicy: "/privacy-policy/",

  profile: "/profile/:slug/",

  slug: "/page/:slug/",

  social: {
    gmri: "http://gmri.org",

    facebook: "https://www.facebook.com/gulfofmaine",
    twitter: "https://twitter.com/gmri",
    instagram: "https://www.instagram.com/gulfofmaineresearchinstitute/",
    youtube: "https://www.youtube.com/user/DevelopmentGMRI",
    linkedin: "https://www.linkedin.com/company/gulf-of-maine-research-institute/",
  },

  trips: {
    create: "/trip/create/",
    root: "/trip/",
    trip: "/trip/:uuid/",
  },

  userAgreement: "/user-agreement/",
  users: {
    profile: "/user/:uuid/",
    root: "/users/",
    manage: "/users/",
  },
}
