/**
 * Navbar Button when user is logged in
 */
import Link from "next/link";
import { usePathname } from 'next/navigation';
import React, { useState } from "react";
import { ButtonDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { paths } from "shared/constants";
import { urlPartReplacer } from "shared/urlParams";
import { QueryData } from "../schema";
const initialState = {
  isOpen: false
};
interface MenuProps extends QueryData {
  logOutUrl: string;
}
export const LoggedInDropdownMenu: React.FC<MenuProps> = ({
  me,
  logOutUrl
}: MenuProps) => {
  const csrftoken = getCookie("csrftoken");
  return <DropdownMenu end={true} data-sentry-element="DropdownMenu" data-sentry-component="LoggedInDropdownMenu" data-sentry-source-file="LoggedInButton.tsx">
      <Link href={urlPartReplacer(paths.users.profile, ":uuid", me.id)} className="dropdown-item nav-item" data-sentry-element="Link" data-sentry-source-file="LoggedInButton.tsx">
        User Profile
      </Link>
      <a href={paths.users.manage} className="dropdown-item nav-item">
        Manage User
      </a>
      {me.cms ? <a href={paths.cms} className="dropdown-item nav-item">
          Content Admin
        </a> : null}
      {me.isStaff ? <a href={paths.admin} className="dropdown-item nav-item">
          Site Admin
        </a> : null}
      {csrftoken ? <form method="post" action={logOutUrl}>
        <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />
        <button type="submit" className="dropdown-item nav-item">Log Out</button>
      </form> : null}
    </DropdownMenu>;
};
type State = Readonly<typeof initialState>;

/**
 * Navbar button when user is logged in
 */
export const LoggedInDropdown = ({
  me
}: QueryData) => {
  const [state, setState] = useState<State>(initialState);
  const pathname = usePathname();
  let name = me.username;
  if (me.firstName.length > 0) {
    name = me.firstName;
  }
  const logOutUrl = paths.auth.logout + "?next=" + pathname;
  return <ButtonDropdown isOpen={state.isOpen} toggle={() => setState({
    isOpen: !state.isOpen
  })} data-sentry-element="ButtonDropdown" data-sentry-component="LoggedInDropdown" data-sentry-source-file="LoggedInButton.tsx">
      <DropdownToggle caret={true} color="primary" id="user-menu" data-sentry-element="DropdownToggle" data-sentry-source-file="LoggedInButton.tsx">
        {name}
      </DropdownToggle>
      <LoggedInDropdownMenu me={me} logOutUrl={logOutUrl} data-sentry-element="LoggedInDropdownMenu" data-sentry-source-file="LoggedInButton.tsx" />
    </ButtonDropdown>;
};

// From https://docs.djangoproject.com/en/4.2/howto/csrf/#acquiring-the-token-if-csrf-use-sessions-and-csrf-cookie-httponly-are-false
function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}