/**
 * Navbar button for when the user is logged out
 */
import * as React from "react";
import { Button } from "reactstrap";
import { login, register } from "../../../shared/auth";

/**
 * Navbar button for when the user is logged out
 */
export const LoggedOutButton: React.FC = () => <React.Fragment>
    <Button onClick={login} color="primary" data-sentry-element="Button" data-sentry-source-file="LoggedOutComponent.tsx">
      Log in
    </Button>

    <Button color="primary" onClick={register} style={{
    marginLeft: "0.5rem"
  }} data-sentry-element="Button" data-sentry-source-file="LoggedOutComponent.tsx">
      Register
    </Button>
  </React.Fragment>;