import { gql } from "@apollo/client"

export interface User {
  id: string
  username: string
  firstName: string
  lastName: string
  isStaff: boolean
  cms: boolean
}

export interface QueryData {
  me: User
}

export const USER_QUERY = gql`
  query me {
    me {
      id
      username
      firstName
      lastName
      isStaff
      cms
    }
  }
`
