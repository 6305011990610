/**
 * Return key, value pairs for a url parameter string
 *
 * @param urlParamString Parameter string from url. With react-router it would be urlParams(this.props.location.search)
 * @returns object with key, value pairs from the given url parameter string
 */
export function urlParams(urlParamString: string): any {
  const pairs = urlParamString.substring(1).split("&")
  const params: any = {}
  for (const index in pairs) {
    const pair = pairs[index]
    const [key, value] = pair.split("=")
    params[key] = value
  }
  return params
}

/**
 * Replace parts of of a react-router URL
 *
 * @param url Source URL with keys to be replaces in the form of /path/:key/to
 * @param key Key to be replaced with leading identifier `:key`
 * @param value Value to replace `:key` with.
 * @returns Url with reformatted string.
 */
export function urlPartReplacer(url: string, key: string, value: string): string {
  return url
    .split("/")
    .map((part) => (part === key ? value : part))
    .join("/")
}

type UrlParts = Map<string, string>

/**
 * Replace the parts of a react-router url string
 * @param url URL with parts to replace denoted by :key
 * @param parts Map of part :key to replace to value
 * @returns formatted URL string
 */
export function urlReplacer(url: string, parts: UrlParts): string {
  let output = url

  for (const [key, value] of parts) {
    output = urlPartReplacer(output, key, value)
  }

  return output
}
