/**
 * Navbar button that will display differently if
 * the user is logged in or logged out
 */
import React from "react";
import { LoadingButton } from "./LoadingButton";
import { LoggedInDropdown } from "./LoggedInButton";
import { LoggedOutButton } from "./LoggedOutComponent";
import { UserQuery } from "../UserQuery";

/**
 * Navbar button that will display differently if
 * the user is logged in or logged out
 */
export const LoginLogoutButton: React.FC = () => <UserQuery LoadingComponent={LoadingButton} LoggedOutComponent={LoggedOutButton} data-sentry-element="UserQuery" data-sentry-component="LoginLogoutButton" data-sentry-source-file="component.tsx">
    {({
    me
  }) => <LoggedInDropdown me={me} />}
  </UserQuery>;