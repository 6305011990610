import { paths } from "shared/constants"

function next(): string {
  return "?next=" + encodeURIComponent(window.location.pathname)
}

export function loginUrl(): string {
  return paths.auth.login + next()
}

export function login() {
  window.location.assign(loginUrl())
}

export function registerUrl(): string {
  return paths.auth.register + next()
}

export function register() {
  window.location.assign(registerUrl())
}
