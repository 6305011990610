"use client";

/**
 * Navbar component
 */
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import { LoginLogoutButton } from "Core/User/NavButton";
import { paths } from "shared/constants";
import logo from "./gmri-ein-logo-white.svg";
import ProjectDropdown from "./ProjectMenu";
import NavLink from "../NavLink";
const Beta = dynamic(() => import("./BetaFlag"), {
  ssr: false
});
/**
 * Navbar component
 */
export default function VSNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prevState: boolean) => !prevState);
  return <Navbar dark={true} expand="lg" className="primary-nav bg-transparent" data-sentry-element="Navbar" data-sentry-component="VSNavbar" data-sentry-source-file="index.tsx">
      <NavbarBrand href={paths.home} className="navbar-logo-container" data-sentry-element="NavbarBrand" data-sentry-source-file="index.tsx">
        <Beta data-sentry-element="Beta" data-sentry-source-file="index.tsx" />
        <Image id="logo" src={logo} alt="Ecosystem Investigation Network" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      </NavbarBrand>

      <NavbarToggler onClick={toggle} data-sentry-element="NavbarToggler" data-sentry-source-file="index.tsx" />

      <Collapse isOpen={isOpen} navbar={true} data-sentry-element="Collapse" data-sentry-source-file="index.tsx">
        <Nav navbar={true} data-sentry-element="Nav" data-sentry-source-file="index.tsx">
          {/* <span id="nav-mark" className="align-middle d-none d-xl-block" /> */}
          <ProjectDropdown data-sentry-element="ProjectDropdown" data-sentry-source-file="index.tsx" />

          <NavLink href={paths.about} data-sentry-element="NavLink" data-sentry-source-file="index.tsx">About</NavLink>

          <NavLink href={paths.faq} data-sentry-element="NavLink" data-sentry-source-file="index.tsx">FAQ</NavLink>

          <NavLink href={paths.partners} data-sentry-element="NavLink" data-sentry-source-file="index.tsx">Partners</NavLink>
        </Nav>
        <Nav navbar={true} className="ms-auto" data-sentry-element="Nav" data-sentry-source-file="index.tsx">
          <Link href={{
          pathname: paths.missions.root,
          query: {
            forGroups: true,
            forIndividuals: true,
            search: undefined
          }
        }} className={"search-icon-link nav-link"} data-sentry-element="Link" data-sentry-source-file="index.tsx">
            <FontAwesomeIcon icon={faSearch} data-testid="search" className="d-none d-md-block search-icon" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="index.tsx" />
            <span className="d-block d-md-none">Search</span>
          </Link>

          <LoginLogoutButton data-sentry-element="LoginLogoutButton" data-sentry-source-file="index.tsx" />
        </Nav>
      </Collapse>
    </Navbar>;
}