"use client";

/**
 * Query for user data, and display specified components once loaded
 */
import { useQuery } from "@apollo/client";
import React from "react";
import { QueryData, USER_QUERY } from "./schema";
interface Props {
  LoadingComponent: React.ComponentType;
  LoggedOutComponent: React.ComponentType;
  children(props: QueryData): JSX.Element;
}

/**
 * Query for user data, and display specified components once loaded
 */
export const UserQuery: React.FC<Props> = ({
  LoggedOutComponent,
  LoadingComponent,
  children
}: Props) => {
  const {
    loading,
    error,
    data
  } = useQuery<QueryData>(USER_QUERY);
  if (loading) {
    return <LoadingComponent />;
  }
  if (error) {
    return <LoggedOutComponent />;
  }
  return children(data!);
};