// So that Next finds images to preload appropriately.
export default function myImageLoader({
  src,
  width,
  quality,
}: {
  src: string
  width: number
  quality: number
}) {
  //   return `http://web:8080${src}`
  return src
}
