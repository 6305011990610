"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";
export default function NavLink({
  href,
  children
}: {
  href: string;
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  let isActive = false;
  if (href === "/" && pathname === "/") {
    isActive = true;
  } else if (href !== "/") {
    isActive = pathname.startsWith(href);
  }
  return <Link href={href} className={"nav-link" + (isActive ? " active" : "")} data-sentry-element="Link" data-sentry-component="NavLink" data-sentry-source-file="index.tsx">
      {children}
    </Link>;
}